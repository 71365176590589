import { render, staticRenderFns } from "./SimpleDialog.vue?vue&type=template&id=0d50371e&scoped=true"
import script from "./SimpleDialog.vue?vue&type=script&lang=js"
export * from "./SimpleDialog.vue?vue&type=script&lang=js"
import style0 from "./SimpleDialog.vue?vue&type=style&index=0&id=0d50371e&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d50371e",
  null
  
)

export default component.exports