<template>
  <portal
    to="modal"
    :data-preload="$options.name"
    :disabled="isPortalDisabled || isPortalDisabledForiOS"
  >
    <article
      v-if="active"
      class="simple-dialog"
      :class="[
        parentClassName,
        classes,
        {
          background: background,
          top: position === 'top',
          center: position === 'center',
          bottom: position === 'bottom',
        },
      ]"
    >
      <div class="simple-dialog__header">
        <template v-if="$slots.headerPrefix">
          <slot name="headerPrefix" />
        </template>
        <template v-else>
          <span class="simple-dialog__title">{{ title }}</span>
          <p v-if="message" class="simple-dialog__message">{{ message }}</p>
        </template>

        <button
          class="simple-dialog__close-button qa-simpleDialogCloseButton"
          :class="gtmClassName ? gtmClassName + 'closeButton' : ''"
          type="button"
          @click="handleClose"
        >
          <CloseSvg class="simple-dialog__close-icon" />
        </button>
      </div>

      <div v-if="$slots.default" class="simple-dialog__body">
        <slot />
      </div>

      <Button
        v-if="action ? action.enabled !== false : false"
        class="simple-dialog__action-button qa-simpleDialogActionButton"
        :title="action.title"
        :modifier="action.modifier"
        :on-click="handleActionClick"
        type="button"
      />
    </article>

    <div
      v-if="active"
      class="simple-dialog-shadow"
      :class="{ background: background }"
    ></div>
  </portal>
</template>

<script>
import scrollMixin from 'Libs/mixins/scrollMixin';
import globalMixin from 'Libs/mixins/globalMixin';
import DeviceHelper from 'Libs/helpers/device';

import CloseSvg from 'Components/00-generated/CloseSvg';
import Button from 'Components/01-atoms/button/Button';

export default {
  name: 'SimpleDialog',
  components: { Button, CloseSvg },
  mixins: [scrollMixin, globalMixin],
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String,
      default: 'top',
    },
    background: {
      type: Boolean,
      default: false,
    },
    headerIcon: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    action: {
      type: [Object, Boolean],
      default: () => ({
        enabled: false,
        title: '',
        modifier: 'primary',
      }),
    },
    // in rare cases the SimpleDialog might be duplicated/opened more than once, to address this bug with the vue-portal plugin, make sure to set this property to true.
    isPortalDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isPortalDisabledForiOS: false,
    };
  },
  computed: {
    parentClassName() {
      return this.$options.parent?.$el?.getAttribute('class') || '';
    },
  },
  watch: {
    active() {
      if (this.active) {
        this.disableBodyScrolling();
      } else {
        this.enableBodyScrolling();
      }
    },
  },
  mounted() {
    /*
      Vue-Portal is disabled by default except in iOS device
      since otherwise it does not behave as expected.
    */
    const deviceHelper = new DeviceHelper();
    this.isPortalDisabledForiOS = deviceHelper.isIosSafari();
  },
  methods: {
    handleClose() {
      this.$emit('close');
    },
    handleActionClick() {
      this.$emit('action-click');
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';

.simple-dialog-shadow {
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;

  &.background {
    z-index: 100;
  }
}

.simple-dialog {
  --dialog-vertical-distance: 90px;
  --dialog-width: 790px;
  --dialog-padding: var(--space-3);
  --title-font-size: var(--font-size-L);
  --title-line-height: var(--font-size-LXL);
  --message-font-size: var(--font-size-M);
  --message-line-height: var(--font-size-ML);
  --body-margin-top: var(--space-6);

  display: flex;
  flex-direction: column;
  position: fixed;
  top: var(--dialog-vertical-distance);
  left: 50%;
  transform: translateX(-50%);
  width: var(--dialog-width);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  z-index: 30;
  background: var(--color-white);
  border-radius: var(--border-radius-default);
  max-height: calc(90vh - var(--dialog-vertical-distance));

  &.background {
    z-index: 200;
  }

  &.top {
    top: var(--dialog-vertical-distance);
  }

  &.bottom {
    bottom: var(--dialog-vertical-distance);
  }

  &.center {
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .simple-dialog__header {
    padding: var(--dialog-padding);
  }

  .simple-dialog__body {
    position: relative;
    padding: var(--dialog-padding);
    overflow: auto;
    border-top: solid 1px var(--color-alto);
    border-bottom: solid 1px var(--color-alto);
  }

  .simple-dialog__action-button {
    display: block;
    margin: var(--space-2--half) auto;
  }
}

.simple-dialog__title {
  font-size: var(--title-font-size);
  line-height: var(--title-line-height);
  font-weight: bold;
}

.simple-dialog__close-button {
  position: absolute;
  right: 25px;
  top: 25px;
  cursor: pointer;
}

.simple-dialog__close-icon {
  fill: var(--color-nero);
  height: 25px;
  width: 25px;
}

.simple-dialog__message {
  font-size: var(--message-font-size);
  line-height: var(--message-line-height);
  color: var(--color-nero);
  margin-top: 5px;
}

@media #{$_mediaMDown} {
  .simple-dialog {
    --dialog-vertical-distance: #{var(--space-8)};
    --dialog-width: 100%;
    --title-font-size: var(--font-size-M);
    --title-line-height: var(--font-size-ML);
    --message-font-size: var(--font-size-S);
    --message-line-height: var(--font-size-SM);
    --body-margin-top: #{var(--space-2)};

    top: var(--dialog-vertical-distance);
    left: 0;
    transform: none;
    max-height: calc(100% - var(--dialog-vertical-distance));
    overflow-y: auto;
    transition: none;
    width: 100%;

    .simple-dialog__header,
    .simple-dialog__body {
      padding: var(--space-2);
    }
  }

  .simple-dialog__close-button {
    right: var(--space-1);
    top: var(--space-2);
    padding: 0;
  }

  .simple-dialog__close-icon {
    height: 20px;
    width: 20px;
  }

  .simple-dialog__action-wrapper {
    width: 100%;
  }

  .simple-dialog__action-button {
    width: 90%;
  }
}
</style>
